import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Vpn Us.Sg ထားပြီပြန်ဝင်ပေးပါ</p>
        <p>1.1.1.1 Vpn / super Vpn ကလွဲ ပြီကျန်တယ့် Vpn အကုန်ရပါတယ်</p>
        <p>လိုက်းဆွဲအားအကောင်းဆုံ Vpnများ speed Vpn / Secure Vpn /phipon</p>
        <a
          className="App-link"
          href="https://mydarksitecollection.online/"
          rel="noopener noreferrer"
        >
          Go To WebSite
        </a>
      </header>
    </div>
  );
}

export default App;
